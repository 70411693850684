<template>
  <div class="d-block">
    <template v-if="data && data.length">
      <v-select
        class="mb-3"
        label="name"
        v-bind:options="data"
        v-on:input="getCategory"
        v-model="selectedCategory"
      >
      </v-select>
    </template>
    <template v-if="selectedCategory && selectedCategory.children.length">
      <TreeSelect
        class="mb-3"
        v-bind:data="selectedCategory.children"
        v-on:get-category="getCategory"
        v-model="selectedCategory"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: "TreeSelect",
  data() {
    return {
      selectedCategory: null,
    };
  },
  props: {
    data: {
      default: () => [],
    },
  },
  methods: {
    getCategory(category) {
      this.$emit("get-category", category);
    },
  },
  watch: {
    selectedCategory: function(newVal) {
      // newVal {id, name, children}
      if (newVal && !newVal.children.length) {
        if (window._treeSelect === undefined) {
          window._treeSelect = {};
        }
        window._treeSelect.selectedCategory = newVal;
        if (this.$parent.selectedCategory) {
          window._treeSelect.selectedCategory.parents.push(
            this.$parent.selectedCategory
          );
        }
      }
    },
  },
};
</script>
