<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="mb-3 sticky-top">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <h5 class="mb-0"><i class="fas fa-stream"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-12 text-center col-md-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="category.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>
    <!-- Content -->
    <div class="row">
      <!-- MAIN CONTENT -->
      <div class="col-12 mb-3 col-xl mb-xl-0">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 col-lg-7">
                <!-- Kategori Adı -->
                <WeInput
                  label="Kategori Adı"
                  placeholder="Kategori Adı"
                  name="category_name"
                  v-bind:required="true"
                  v-model="form.name"
                  v-bind:error="$v.form.name.$error"
                />
                <!-- ./Kategori Adı -->

                <!-- Slug -->
                <WeSlugCheck
                  v-bind:value="form.name"
                  v-bind:id="$route.params.id"
                  v-bind:slug="form.slug"
                  v-bind:search="true"
                  v-on:slugify="changeSlug"
                  url-fragment="kategori"
                  type="category"
                />
                <!-- ./Slug -->

                <!-- Tax Amount -->
                <WeInput
                  label="KDV Oranı"
                  prepend="%"
                  v-bind:filter-number="true"
                  v-model="form.taxAmount"
                  v-bind:error="$v.form.taxAmount.$error"
                />
                <!-- ./Tax Amount -->
                <div class="mb-2">
                  <label class="custom-label">Kategori Durumu</label>
                  <div class="row">
                    <div class="col">
                      <WeSwitch
                        v-model="form.is_active"
                        v-bind:show-status="true"
                      />
                    </div>
                    <div class="col">
                      <WeSwitch
                        v-model="form.showcase"
                        label="Vitrinde Göster"
                      />
                    </div>
                    <div class="col">
                      <WeSwitch v-model="form.showmenu" label="Menüde Göster" />
                    </div>
                  </div>
                </div>

                <WeImageUploader
                  v-model="form.icon"
                  title="Simge"
                  v-bind:fixedMetaTitle="form.name"
                  v-bind:fixedMetaAlt="form.name"
                />
                <WeImageUploader
                  v-model="form.image"
                  title="Görsel"
                  v-bind:fixedMetaTitle="form.name"
                  v-bind:fixedMetaAlt="form.name"
                />
              </div>
              <div class="col-12 col-lg">
                <WeCategory
                  v-bind:title="
                    $filters.toUpperCase($t('parentCategorySelection'))
                  "
                  v-bind:current-category="$route.params.id"
                  v-bind:language="category.lang"
                  v-bind:multiple="false"
                  v-bind:draggable="true"
                  v-bind:placeholder="$t('searchCategory')"
                  v-model="form.parentCategory"
                />
              </div>
            </div>

            <!-- Description -->
            <WeDescription
              v-model="form.description"
              v-bind:access-token="session.accessToken"
              v-bind:name="'category'"
              v-bind:title="$t('description')"
            />
            <!-- ./Description -->

            <!-- Meta -->
            <WeMetaData v-model="form.meta" />
            <!-- ./Meta -->
          </div>
        </div>
      </div>
      <!-- ./MAIN CONTENT -->

      <!-- ASIDE -->
      <div class="col-12 mb-3 col-xl-4 mb-xl-0">
        <!-- Feature Section -->
        <WeFeature v-model="form.features" />
        <!-- ./Feature Section -->
        <!-- Marketplace Category -->
        <MarketplaceCategoryTree
          v-model="form"
          v-bind:config-status="marketplaceConfig"
        />
        <!-- ./Marketplace Category -->
      </div>
      <!-- ./ASIDE -->
    </div>
    <!-- ./Content -->

    <WeSeoBar
      v-bind:focus-keyword="form.name"
      v-bind:metaTitle="form.meta.title"
      v-bind:metaDescription="form.meta.description"
      v-bind:description="form.description.text"
      v-bind:images="form.image.files"
      v-bind:slug="form.slug"
    />
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import MarketplaceCategoryTree from "./views/MarketplaceCategoryTree/Index";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      form: {
        name: null,
        taxAmount: 0,

        is_active: true,
        showcase: false,
        showmenu: true,
        description: {
          text: null,
          show: false,
          place: "before",
        },

        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },

        image: {
          files: [],
          filesData: null,
        },

        icon: {
          files: [],
          filesData: null,
        },

        parentCategory: [],
        marketplaces: [],
        features: [],
        google_category: null,
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  components: {
    MarketplaceCategoryTree,
  },
  validations: {
    form: {
      name: {
        required,
      },
      taxAmount: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("category", ["create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData, [
          "image",
          "icon",
          "marketplaces",
          "google_category",
          "description"
        ]);
        this.updateCategory();
      } else {
        this.addCategory();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Kategori listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addCategory() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateCategory() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getCategoryById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(result);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/categories");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["category", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "KATEGORİ DÜZENLE" : "KATEGORİ EKLE";
    },
    checkPermission() {
      return permission.check("category", "u");
    },
    marketplaceConfig() {
      let mpConfig = this.session.config["site.marketplace_integration"];
      return mpConfig == 1 || mpConfig == "1";
    },
  },
  mounted() {
    this.getCategoryById();
  },
  watch: {
    "category.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getCategoryById();
        }
      },
    },
  },
};
</script>
