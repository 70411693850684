<template>
  <div>
    <template v-if="data && data.length">
      <div class="col-12 p-0">
        <v-select
          class="custom-v-select-sm mb-3"
          label="name"
          placeholder="Kategori Seçin"
          v-bind:options="data"
          v-bind:disabled="disabled"
          v-on:input="getCategory"
          v-model="selectedCategory"
        />
      </div>
    </template>
    <template v-if="selectedCategory && selectedCategory.children.length">
      <TreeSelect
        class="mb-3"
        v-bind:data="selectedCategory.children"
        v-model="selectedCategory"
        v-on="$listeners"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: "TreeSelect",
  data() {
    return {
      selectedCategory: null,
    };
  },
  props: {
    data: {
      default: () => [],
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    getCategory(category) {
      this.$emit("get-category", category);
    },
  },
  watch: {
    selectedCategory: function (newVal) {
      // newVal {id, name, children}
      if (newVal && !newVal.children.length) {
        this.$emit("selected-category", newVal);
      }
    },
  },
};
</script>
