<template>
  <div>
    <ul class="list-group">
      <!-- Other -->
      <li class="list-group-item btn-light rounded-top-0 border-top-0">
        <div class="d-flex align-items-center">
          <img
            src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            class="img-fluid"
            alt=""
            width="60"
          />
          <div class="col ml-2">
            <span>Google</span>
          </div>
          <!-- Create -->
          <span
            v-if="!data"
            class="col-auto btn btn-sm btn-outline-primary"
            v-on:click="showDetail(false)"
            ><i class="fas fa-edit fa-sm"></i
          ></span>
          <!-- /Create -->

          <!-- Edit -->
          <span
            v-else
            class="
              cursor-pointer
              font-12
              badge
              my-1
              py-1
              px-2
              btn-indigo
              text-truncate
            "
            v-on:click="showDetail(true)"
            v-tooltip.bottom="data.name"
            ><i class="fas fa-times fa-sm"></i> {{ data.name }}</span
          >
          <!-- ./Edit -->
        </div>
      </li>
      <!-- ./Other -->
    </ul>
    <WeModal
      v-if="showModal"
      title="Google Kategori Eşleştirme"
      modal-width="50"
      v-on:close="closeModal"
    >
      <div slot="body">
        <template v-if="ready">
          <TreeSelect
            v-bind:data="detail.categories"
            v-on:get-category="getCategory"
            v-on:selected-category="category = $event"
          />

          <!-- Empty Category Info -->
          <WeCard class="alert-info" v-if="!detail.categories.length">
            <i class="fas fa-info-circle mr-2"></i>
            <span>Google Kategoriler mevcut değil.</span>
          </WeCard>
          <!-- ./Empty Category Info -->

          <WeSubmitButton
            v-if="detail.categories.length"
            v-bind:disabled="category == null"
            v-on:submit="onSaveCategory"
          />
        </template>

        <div style="min-height: 250px; overflow: hidden" v-else>
          <WeLoading />
        </div>
      </div>
    </WeModal>
  </div>
</template>
<script>
import TreeSelect from "./TreeSelect/Index";
import { mapActions, mapState } from "vuex";

export default {
  name: "GoogleCategory",
  data() {
    return {
      ready: false,
      showModal: false,
      detail: {
        categories: [],
      },
      category: null,
    };
  },
  components: {
    TreeSelect,
  },
  props: {
    data: {
      default: () => {},
    },
    configStatus: {
      default: false,
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    ...mapActions("category", ["getGoogleCategories"]),
    showDetail(remove) {
      if (remove) {
        this.$swal({
          title: "İşlemi Onaylıyor Musunuz ?",
          text: "Mevcut kategoriyi kaydettiğinizde, Google Kategori Eşleştirmesi kaldırılacak",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "İptal",
          confirmButtonText: "Kaldır",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.$emit("input");
          }
        });
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      this.showModal = true;
      this.ready = false;
      this.getGoogleCategories({
        onSuccess: (result) => {
          const resultData =
            result.data && result.data.data ? result.data.data : [];
          this.detail.categories = resultData;
        },
        onFinish: () => {
          this.ready = true;
        },
      });
    },
    closeModal() {
      this.showModal = false;
      this.detail = {
        categories: [],
      };
      this.category = null;
    },
    getCategory(category) {
      if (category) {
        this.detail.code = category.id;
        this.getGoogleCategories({
          category_id: category.id,
          onSuccess: (result) => {
            const resultData =
              result.data && result.data.data ? result.data.data : [];
            if (category) {
              category.children = resultData;
            } else {
              this.detail.categories = resultData;
            }
          },
        });
      } else {
        this.detail.code = null;
        this.category = null;
      }
    },
    onSaveCategory() {
      if (
        this.category &&
        (!this.category.children || !this.category.children.length)
      ) {
        this.$emit("input", this.category);
        this.closeModal();
      }
    },
  },
};
</script>
