<template>
  <WeCard>
    <div v-if="ready">
      <h5 class="custom-card-title">
        <i class="fas fa-sync"></i>
        <span>KATEGORİ EŞLEŞTİRME</span>
      </h5>
      <hr />
      <ul class="list-group" v-if="configStatus && marketplaceList.length">
        <li
          class="list-group-item btn-light"
          v-bind:class="{
            'rounded-bottom-0': index == marketplaceList.length - 1,
          }"
          v-for="(mp, index) in marketplaceList"
          v-bind:key="mp.id"
        >
          <div class="d-flex align-items-center">
            <!-- Marketplace Logo & Name -->
            <img
              v-if="mp.logo"
              v-bind:src="mp.logo"
              class="img-fluid"
              width="60"
            />
            <i
              class="fas fa-camera fa-lg text-muted"
              v-else
              style="text-align: center; width: 60px"
            ></i>
            <span class="ml-2 col">
              {{ mp.title }}
            </span>
            <!-- ./Marketplace Logo & Name -->

            <!-- Selected Category -->
            <span
              v-if="mp.selectedCategory"
              class="
                cursor-pointer
                font-12
                badge
                my-1
                py-1
                px-2
                btn-indigo
                text-truncate
              "
              v-on:click="removeSelectedCategory(mp)"
              v-tooltip.bottom="mp.selectedCategory.name"
            >
              <i class="fas fa-times"></i> {{ mp.selectedCategory.name }}
            </span>
            <!-- ./Selected Category -->

            <!-- Edit -->
            <span
              v-if="!mp.selectedCategory"
              class="btn btn-sm btn-outline-primary"
              v-bind:class="{ 'disabled btn-disabled': !mp.is_active }"
              v-on:click="selectMarketplace(mp)"
              ><i class="fas fa-edit fa-sm"></i
            ></span>
            <!-- ./Edit -->
          </div>
        </li>
        <GoogleCategory v-model="data.google_category" />
      </ul>
      <WeCard v-else class="alert-info">
        <div class="d-flex align-items-center">
          <i class="fas fa-info-circle mr-2"></i>
          <template v-if="!configStatus">
            <span>Pazaryeri entegrasyonu aktif değil.</span>
          </template>
          <template v-else>
            <div class="d-flex align-items-center justify-content-between">
              <span>Aktif pazaryeri mevcut değil.</span>
              <a href="/marketplaces" class="btn btn-sm btn-white font-12 ml-2"
                >Pazaryeri Ayarları <i class="fas fa-external-link-alt"></i
              ></a>
            </div>
          </template>
        </div>
      </WeCard>
    </div>
    <div class="position-relative" style="min-height: 250px" v-else>
      <WeLoading />
    </div>
    <WeModal
      v-if="showModal"
      v-bind:title="getTitle"
      modal-width="50"
      v-on:close="closeModal"
    >
      <div slot="body">
        <TreeSelect
          v-bind:data="detail.categories"
          v-on:get-category="getCategory"
          v-on:selected-category="category = $event"
        />
        <WeSubmitButton v-on:submit="onSaveCategory" />
      </div>
    </WeModal>
  </WeCard>
</template>
<script>
import TreeSelect from "./TreeSelect/Index";
import GoogleCategory from "../GoogleCategory/Index.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "MarketplaceCategoryTree",
  data() {
    return {
      ready: false,
      showModal: false,
      detail: {
        categories: [],
      },
      category: null,
      marketplaceList: [],
    };
  },
  components: {
    TreeSelect,
    GoogleCategory,
  },
  props: {
    data: {
      default: () => {},
    },
    configStatus: {
      default: false,
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    ...mapActions("marketplace", ["getList", "getMarketplaceCategory"]),
    selectMarketplace(market) {
      if (market.is_active) {
        helper.copy(market, this.detail);
        this.getMarketplaceCategory({
          marketplace: market,
          onSuccess: (result) => {
            this.detail.categories = this.objToArray(
              result.data && result.data.data ? result.data.data : null
            );
          },
          onFinish: () => {
            if (this.detail.categories && this.detail.categories.length) {
              this.showModal = true;
            }
          },
        });
      }
    },
    objToArray(items) {
      const resultData = [];
      if (items) {
        const dataObj = JSON.parse(items);
        Object.entries(dataObj).forEach(([key, item]) => {
          const data = {};
          data.id = key;
          data.name = item;
          data.children = [];
          data.parents = [];

          resultData.push(data);
        });
      }
      return resultData;
    },
    closeModal() {
      this.showModal = false;
      this.detail = {
        categories: [],
      };
      this.category = null;
      if (window.hasOwnProperty("_treeSelect")) {
        delete window._treeSelect.selectedCategory;
      }
    },
    getCategory(category) {
      if (category) {
        this.detail.code = category.id;
        this.getMarketplaceCategory({
          marketplace: this.detail,
          onSuccess: (result) => {
            const resultData = this.objToArray(
              result.data && result.data.data ? result.data.data : null
            );
            if (category) {
              category.children = resultData;
            } else {
              this.detail.categories = resultData;
            }
          },
        });
      }
    },
    onSaveCategory() {
      const selectedCategory = window._treeSelect
        ? window._treeSelect.selectedCategory
        : null;
      if (selectedCategory && !selectedCategory.children.length) {
        const data = {};
        data.id = selectedCategory.id;
        data.name = selectedCategory.name;
        data.parents = selectedCategory.parents;

        this.category = data;
        this.saveCategoryToMarket();
      }
    },
    saveCategoryToMarket() {
      const index = this.checkMarketplace();
      const result = {
        marketplace_id: this.detail.id,
        category_code: this.category.id,
        category_name: this.getCategoryBreadcrumb(this.category),
      };

      if (index < 0) {
        // Create new data
        this.data.marketplaces.push(result);
      } else {
        this.data.marketplaces[index] = result;
      }
      this.data.marketplaces.sort();
      this.updateMarketplaceList();

      this.closeModal();
    },
    checkMarketplace(id) {
      id = id || this.detail.id;
      return this.data.marketplaces.findIndex(
        (item) => item.marketplace_id === id
      );
    },
    updateMarketplaceList() {
      this.marketplaceList.map((item) => {
        if (item.id === this.detail.id) {
          item.selectedCategory = this.category;
        }
        return item;
      });
    },
    removeSelectedCategory(mp) {
      this.$swal({
        title: "İşlemi Onaylıyor musunuz ?",
        text: "Mevcut Kategoriyi güncellediğinizde Pazaryeri eşleştirmesi kaldırılacak",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Kaldır",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const dataIndex = this.data.marketplaces.findIndex(
            (item) => item.category_code == mp.selectedCategory.id
          );
          if (dataIndex >= 0) {
            this.data.marketplaces.splice(dataIndex, 1);
            delete mp.selectedCategory;
            this.marketplaceList.sort();
          }
        }
      });
    },
    setMarketplaceList() {
      this.marketplaceList = this.marketplace.list.filter((item) => {
        const selectedIndex = this.data.marketplaces
          ? this.data.marketplaces.findIndex(
              (category) => category.marketplace_id === item.id
            )
          : 0;

        if (selectedIndex >= 0) {
          const categoryMarketplace = this.data.marketplaces[selectedIndex];
          const selectedCategory = {};
          selectedCategory.id = categoryMarketplace.category_code;
          selectedCategory.name = categoryMarketplace.category_name
            .split("/")
            .reverse()[0];

          item.selectedCategory = selectedCategory;
        }
        return item;
      });
    },
    getCategoryBreadcrumb(category) {
      let result = "";
      let parents = [];

      function getCategoryParents(category) {
        if (category.parents && category.parents.length) {
          parents.push(category.parents[0].name);
          getCategoryParents(category.parents[0]);
        }
      }

      getCategoryParents(category);

      if (parents && parents.length) {
        parents = parents.reverse();
        parents.push(category.name);
        result = parents.join("/");
      } else {
        result = category.name;
      }

      return result;
    },
  },
  computed: {
    ...mapState({
      marketplace: (state) => state.marketplace,
    }),
    getTitle() {
      return (this.detail.title || "") + " Kategori Eşleştirme";
    },
  },
  mounted() {
    this.getList({
      onFinish: () => {
        this.ready = true;
        this.setMarketplaceList();
      },
    });
  },
};
</script>
